import React, { useEffect, useRef, useState } from "react";
import {i18n} from "./index";

const I18NContext = React.createContext();

export const I18nProvider = ({ children }) => {
  const [locale, setLocale] = useState("en"); // TODO: Switch EN -> PT-BR
  const supportedLocales = useRef(["pt-BR", "en"]);

  useEffect(() => {
    i18n.lang = locale;
  }, [locale]);

  return (
    <I18NContext.Provider
      value={{
        locale,
        supportedLocales: supportedLocales.current,
        setLocale,
      }}
    >
      {children}
    </I18NContext.Provider>
  );
};

export default I18nProvider;
