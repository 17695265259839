import ptBR from "./pt-BR";
import en from "./en";

export const i18n = {
  lang: "en",
  supportedLanguages: {
    "pt-BR": ptBR,
    en,
  },
};

function pluralize(value, options) {
  if (value === 0) {
    return message(options.none);
  }
  if (value === 1) {
    return message(options.one);
  }
  if (value > 1) {
    return message(options.many);
  }
}

function message(key) {
  const language = i18n.supportedLanguages[i18n.lang];

  if (typeof language[key] !== "undefined") {
    return language[key];
  }
  return `${i18n.lang}.${key}`;
}

export function Message(string) {
  return message(string);
}

export function Pluralize(value, strings) {
  const options = {
    none: strings.none || strings.one,
    one: strings.one,
    many: strings.many || strings.one,
  };

  return pluralize(value, options);
}
