export const ptBR = {
  "welcomedialog.title": "Bem-vindo ao Xlung Trainer",
  "welcomedialog.body": "Aprenda fazendo exercícios simulados de VM",
  "welcomedialog.alreadySubscriber": "Sou assinante",
  "welcomedialog.letMeTry": "Quero experimentar",

  "duration.in.minutes": "minutos",
  "breadcrumbs.exercises": "Exercícios",
  "breadcrumbs.exercises.results": "Resultados",
  "breadcrumbs.assessments": "Avaliações",
  "breadcrumbs.assessments.results": "Resultados",
  "breadcrumbs.assessments.question.results": "Resultados",

  "breadcrumbs.assessments.question": "Questão",

  "loading": "Carregando",
  "date.time.format": "dd/MM/yyyy HH:mm",
  "success": "Sucesso",

  "assessment.resolution.confirm": "Confirmar",
  "assessment.resolution.cancel": "Cancelar",
  "assessment.resolution.confirmSubmission": "Confirmar Submissão",

  "assessment.resolution.multipleChoiceQuestion": "**Pelo menos uma questão de múltipla escolha não foi respondida**. Deseja finalizar mesmo assim?",
  "assessment.resolution.ventilatorSettingsNotChanged": "**As configurações do ventilador não foram alteradas**. Deseja finalizar mesmo assim?",

  "assessment.resolution.error.closed.title": "Fora do prazo de avaliação",
  "assessment.resolution.error.closed.body": "Você tentou enviar uma resposta para a avaliação, no entanto o prazo está encerrado. Caso necessite de ajuda entre em contato com o administrador.",

  "assessment.resolution.retry.error.overlimit.title": "Você ultrapassou o limite de tentativas de resolução da prova.",
  "assessment.resolution.retry.error.overlimit.body": "Caso necessite de maiores informações entre em contato com o administrador.",
  "assessment.remainingTries": "Tentativas disponíveis",

  "assessment.actions.addStudent": "Adicionar Aluno",
  "assessment.actions.addQuestion": "Adicionar Questão",
  "assessment.actions.delete": "Remove a questão da avaliação",
  "assessment.actions.move": "Altera a ordem da questão na avaliação",
  "assessment.action.start": "Iniciar Avaliação",

  "assessment.result.header.title": "Resultado",

  "assessment.result.assessmentSubmited.title": "Avaliação submetida com sucesso!",
  "assessment.result.assessmentSubmited.body": "**Parabéns!** Sua avaliação foi enviada com sucesso.",

  "assessment.result.question.grade": "Nota",
  "assessment.result.question.solution": "Resposta",
  "assessment.result.question.title": "Questão",
  "assessment.result.question.order": "#",
  "assessment.result.empty": "Esta avaliação ainda não tem resultados até o momento",
  "assessment.result.student": "Aluno(a)",
  "assessment.result.await.title": "Aguardando liberação",
  "assessment.result.await.release": "Os resultados da avaliação serão divulgados em breve. Aguarde orientação do responsável pela prova.",

  "assessment.result.backhome": "Retornar",

  "assessment.result.sendReportEmail": "Enviar relatório por email",
  "assessment.exportResolution.messageSuccess": "Relatório enviado com sucesso para o(s) email(s) do(s) autor(es)",

  "assessment.result.finalScore": "Nota Final",
  "assessment.result.resolutionSent": "Resposta enviada em",

  "assessment.result.blankResolution": "Questão não resolvida",

  "assessment.student.finalGrade": "Média",
  "assessment.student.email": "Email",
  "assessment.student.name": "Nome",
  "assessment.search.students": "Pesquisar alunos",
  "assessment.student.search.result.empty": "Sua busca não retornou resultados",
  "assessment.student.search.by.name.or.email": "Nome ou Email do aluno",
  "assessment.student.search.results": "Resultados da Busca",
  "assessment.student.search.placeholder": "Nome ou Email do aluno",
  "assessment.student.search.add": "Adicionar",

  "assessment.question.level": "Dificuldade",
  "assessment.question.duration": "Duração Máxima (min)",
  "assessment.question.title": "Título",
  "assessment.question.order": "Ordem",

  "assessment.instructions": "Instruções",
  "assessment.authors": "Autor(es)",

  "username": "Usuário",
  "password": "Senha",
  "required": "Preenchimento obrigatório",
  "Auth.form.error.invalid": "Usuário ou Senha inválidos",

  "simulation.save": "Salvar",
  "simulation.commit": "Confirmar ajustes?",
  "simulation.open": "Abrir",
  "simulation.pause": "Pausar",
  "simulation.resume": "Continuar",
  "simulation.undo": "Desfazer",
  "simulation.redo": "Refazer",
  "simulation.configuration": "Configurações",
  "simulation.images": "Exames",
  "simulation.sound": "Som",
  "simulation.requestGasometry": "Solicitar Gasometria",
  "simulation.finishEarlyMessage": "Você poderá finalizar o exercício<br> após 30 segundos do início",
  "simulation.timeLeft": "Tempo restante",

  "select.placeholder": "Selecione",
  "minutes": "minutos",
  "minute": "minuto",
  "second": "segundo",
  "seconds": "segundos",
  "and": "e",
  "previous": "Anterior",
  "next": "Próximo",
  "finish": "Finalizar",
  "cancel": "Cancelar",

  "header.clientNameOrSignIn": "Login",
  "header.logout": "Sair",
  "header.becomePremium": "Seja Premium",
  "header.exerciseList": "Lista de Exercícios",
  "header.assessmentsList": "Avaliações",

  "gender.male": "Masc.",
  "gender.female": "Fem.",
  "age": "Idade",
  "age.unit": "Anos",
  "height": "Altura",
  "height.unit": "m",
  "weight": "Peso",
  "weight.unit": "Kg",
  "idealBodyWeight": "Peso Ideal",
  "airwayResistance": "Resistência nas vias aéreas",
  "airwayResistance.description": "É uma propriedade física que mede a oposição ao fluxo de ar nas zonas condutoras da árvore brônquica",
  "airwayResistance.unit": "cmH₂O/l/s",
  "lungCompliance": "Complacência Pulmonar",
  "lungCompliance.unit": "ml/cmH₂O",
  "lungCompliance.description": "<p>É uma propriedade física do tecido pulmonar e corresponde à capacidade pulmonar total em litros a uma dada pressão alveolar.</p><p><code><var>C</var> = <var>dV</var>/<var>dP</var></code></p><p>Pode ser considerada, portanto, o inverso da elastância pulmonar.</p>",
  "shuntEffect": "Efeito Shunt",
  "shuntEffect.description": "Ocorre quando uma porção do sangue venoso adentra a circulação arterial sem haver se submetido à troca gasosa na circulação pulmonar.",
  "shuntEffect.unit": "%",
  "deadSpace": "Espaço Morto",
  "deadSpace.description": "Proporção entre a ventilação do espaço-morto e o volume corrente. Espaço-morto é a região das vias aéreas que é ventilada, mas não realiza troca gasosa.",
  "muscleEffort": "Intensidade do Esforço",
  "muscleEffort.description": "É uma estimativa da pressão gerada na cavidade pleural pela contração do diafragma.",
  "muscleEffort.unit": "cmH₂O",
  "frequency": "Frequência Respiratória",
  "frequency.description": "Em adultos, varia entre 16 e 20 irpm.",
  "frequency.unit": "rpm",
  "frequencyParam": "f",
  "frequencyParam.unit": "s",
  "duration.unit": "s",
  "inspiratoryPause": "Pausa Insp.",
  "inspiratoryPause.unit": "s",
  "expiratoryPause": "Pausa Exp.",
  "expiratoryPause.unit": "s",
  "inspiratoryTime": "T. Insp.",
  "inspiratoryTime.unit": "s",
  "expiratoryTime": "T. Exp.",
  "peep": "PEEP",
  "peep.unit": "cmH₂O",
  "fio2": "FɪO₂",
  "fio2.unit": "%",
  "pressureDelta": "Δp",
  "pressureDelta.unit": "cmH₂O",
  "riseTime": "T. Subida",
  "riseTime.unit": "s",
  "supportPressure": "PS",
  "supportPressure.unit": "cmH₂O",
  "endOfCycle": "Fim do ciclo",
  "endOfCycle.unit": "%",
  "sensitivityType": "Tipo de Sensibilidade",
  "sensitivity": "Sensibilidade",
  "volume": "Volume",
  "volume.unit": "ml",
  "flow": "Fluxo",
  "flow.unit": "l/min",
  "flowPattern": "Formato",
  "syncTrigger.flow": "Fluxo",
  "syncTrigger.pressure": "Pressão",
  "pressure": "Pressão",
  "ventilator": "Ventilador",
  "ventilationMode": "Modo ventilatório",
  "ventilation": "Ventilação",
  "oximetry": "Oximetria",
  "gasometry": "Gasometria",
  "collectedAt": "Colhida aos",
  "gasometryContinuous": "Contínua",
  "ventilometry": "Ventilometria",
  "tidalVolume": "VC",
  "synchrony": "Sincronia",
  "respiratoryMechanics": "Mecânica Respiratória",
  "respiratoryWorkWeaning": "Trabalho Resp./Desmame",
  "peakPressure": "P. Pico",
  "drivingPressure": "Driving P.",
  "workOfBreath": "W",
  "tobinIndex": "f/VT",
  "visualization": "Visualizar",

  // Graphics Screen
  "simulation.title.graphics": "Gráficos",
  "simulation.title.autoScale": "Escala automática",

  //categories
  "category.0": "Ajustes Básicos e modos da VM",
  "category.1": "Ajustes da VM na SARA",
  "category.2": "Ajustes da VM nas D. Obstrutivas: DPOC e ASMA",
  "category.3": "Ajustes da VM nas D. Neurológicas",
  "category.4": "Ajustes da VM em assincronias",
  "category.5": "Outros",

  //Clinical Conditions
  "clinicalConditions.title": "Condições Clínicas",
  "clinicalConditions.NORMAL_INTUBATED_WITHOUT_EFFORT": "Normal intubado sem esforço",
  "clinicalConditions.NORMAL_INTUBATED_WITH_EFFORT": "Normal intubado com esforço",
  "clinicalConditions.NORMAL_NON_INTUBATED": "Normal não intubado",
  "clinicalConditions.COPD": "DPOC",
  "clinicalConditions.ASTHMA": "Asma",
  "clinicalConditions.LIGHT_ARDS": "SARA Leve",
  "clinicalConditions.MODERATE_ARDS": "SARA Moderada",
  "clinicalConditions.SERIOUS_ARDS": "SARA Grave",
  "clinicalConditions.CUSTOM": "Personalizado",

  //Metabolic Status
  "metabolicDisorders.title": "Distúrbios Metabólicos",
  "metabolicDisorders.NEUTRAL": "Sem distúrbios metabólicos",
  "metabolicDisorders.LIGHT_ACIDOSIS": "Acidose leve",
  "metabolicDisorders.MODERATE_ACIDOSIS": "Acidose moderada",
  "metabolicDisorders.SEVERE_ACIDOSIS": "Acidose grave",
  "metabolicDisorders.LIGHT_ALKALOSIS": "Alcalose leve",
  "metabolicDisorders.MODERATE_ALKALOSIS": "Alcalose moderada",
  "metabolicDisorders.SEVERE_ALKALOSIS": "Alcalose grave",

  //Elevations
  "elevation.title": "Altitude",
  "elevation.sea.level": "Nível do Mar",
  "elevation.deadsea.level": "Nível do Mar Morto",
  "elevation.saopaulo.level": "Nível de São Paulo",
  "elevation.denver.level": "Nível de Denver",
  "elevation.airplane.level": "Nível de um avião",
  "elevation.lapaz.level": "Nível de La Paz",

  // Ventilatory Modes
  "ventilatoryModes.VCV": "A/C VCV",
  "ventilatoryModes.PCV": "A/C PCV",
  "ventilatoryModes.PSV": "PSV",
  "ventilatoryModes.CPAP": "CPAP",

  // Exercise List
  "exerciseList.freeContent": "Exercícios Gratuitos",
  "exerciseList.exercises": "Exercícios:",
  "exerciseList.premiumContent": "Exercícios Premium",
  "exerciseList.question.level": "Nível",
  "exerciseList.question.duration": "Duração máxima (min)",
  "exerciseList.question.title": "Título",
  "exerciseList.question.order": "Ordem",

  "exercise.list.header": "Meus exercícios",
  "exercise.level.easy": "Fácil",
  "exercise.level.medium": "Médio",
  "exercise.level.hard": "Difícil",

  // Assessments List
  "assessmentsList.noAssessmentsAvailable": "Você não tem avaliações disponíveis no momento.",
  "assessmentsList.institutionalTitle": "Minhas Avaliações",
  "assessmentsList.assessmentTitle": "Título",
  "assessmentsList.accessBegin": "Início para Acesso",
  "assessmentsList.accessEnd": "Fim do Acesso",
  "assessmentsList.remainingTries": "Tentativas disponíveis",
  "assessmentsList.resolutions": "Resoluções",

  // Assessment Details
  "assessment.details.basicInfo": "Informações Básicas",
  "assessment.details.questions": "Questões",
  "assessment.details.result": "Resultado",
  "assessment.details.registeredStudents": "Alunos Inscritos",
  "assessment.details.results": "Resultados",

  // ExerciseBuilder
  "exerciseBuilder.learningObjectives": "Objetivos de Aprendizado",
  "exerciseBuilder.header": "Construtor de Exercícios",
  "exerciseBuilder.objectives": "Objetivos de Aprendizado",
  "exerciseBuilder.challengeLevel": "Nível de Dificuldade",
  "exerciseBuilder.clinicalScenario": "Cenário Clínico",
  "exerciseBuilder.tasksAndDuration": "Missão do Aluno",
  "exerciseBuilder.mediaUpload": "Exames médicos disponíveis",
  "exerciseBuilder.simulationConfig": "Configuração da Simulação",
  "exerciseBuilder.simulationSolution": "Solução da Simulação",
  "exerciseBuilder.extraQuestionBuilder": "Questão Avulsa",
  "exerciseBuilder.expectedResults": "Pontuação",
  "exerciseBuilder.evolutions": "Evoluções do Paciente",
  "exerciseBuilder.finalization": "Finalização do Exercício",
  "exerciseBuilder.finalized": "Exercício Finalizado",
  "exerciseBuilder.solutionSummarized": "Resultados",

  // ExerciseBuilder - Clinical Scenario
  "exerciseBuilder.clinicalScenario.placeholders.exerciseName": "Digite aqui o nome do exercício de simulação",
  "exerciseBuilder.clinicalScenario.placeholders.objectives": "Digite aqui os objetivos de aprendizagem",
  "exerciseBuilder.clinicalScenario.placeholders.description": "",
  "exerciseBuilder.clinicalScenario.placeholders.tasks": "",

  "exerciseBuilder.scenario.description": "Descreva o cenário clínico do exercício",

  // ExerciseBuilder - ExtraQuestion
  "exerciseBuilder.extraQuestionStem.description": "Enunciado",
  "exerciseBuilder.extraQuestionStem.alternativesLabel": "Alternativas",
  "exerciseBuilder.extraQuestionStem.placeholders.stem": "Digite aqui o enunciado da questão",
  "exerciseBuilder.extraQuestionStem.placeholders.alternative": "Digite aqui o texto da opção",

  // ExerciseBuilder - ExerciseName
  "exerciseBuilder.exerciseName.description": "Nome do exercício de simulação",

  // ExerciseBuilder - ExerciseCategories
  "exerciseBuilder.categories.description": "Selecione as categorias que melhor descrevem este exercício.",
  "exerciseBuilder.objectives.description": "Descreva os objetivos do exercício",
  "exerciseBuilder.tasks.description": "Descreva pontualmente as tarefas do exercício",

  // ExerciseBuilder - ExerciseLevel
  "exerciseBuilder.level.description": "Selecione o nível de dificuldade do exercício.",

  // ExerciseBuilder - ExerciseMedia
  "exerciseBuilder.media.empty": "Nenhum arquivo de mídia anexado ao exercício",
  "exerciseBuilder.media.message": "Arraste para cá ou clique para selecionar os arquivos dos exames.",
  "exerciseBuilder.media.uploadButton": "Enviar Imagem",
  "exerciseBuilder.media.description": "Selecione os arquivos de mídia (imagens, vídeos, etc) que estarão disponíveis para visualização durante o exercício.",

  // ExerciseBuilder - Simulation Duration
  "exerciseBuilder.duration.description": "Quanto tempo o aluno terá disponível para a execução do exercício?",

  // ExerciseBuilder - Simulation Configuration
  "exerciseBuilder.configuration.preview": "Visualizar no simulador",

  // ExerciseBuilder --- Patient
  "exerciseBuilder.patientSetup.description": "Configure o paciente de acordo com o caso clínico:",
  "exerciseBuilder.configuration.patient.demographicData": "Dados Demográficos",
  "exerciseBuilder.configuration.patient.gender": "Sexo",
  "exerciseBuilder.configuration.patient.pulmonaryPhysiology": "Fisiologia Pulmonar",
  "exerciseBuilder.configuration.patient.airwayResistance": "Rva",
  "exerciseBuilder.configuration.patient.lungCompliance": "Cst",
  "exerciseBuilder.configuration.patient.deadSpace": "VD/VT",
  "exerciseBuilder.configuration.patient.shuntEffect": "Shunt",
  "exerciseBuilder.configuration.patient.effortData": "Esforço Muscular",
  "exerciseBuilder.configuration.patient.muscleEffort": "Pmus",
  "exerciseBuilder.configuration.patient.frequency": "f",
  "exerciseBuilder.configuration.patient.duration": "Duração",
  "exerciseBuilder.configuration.patient.clinicalCondition": "Condição Clínica",
  "exerciseBuilder.configuration.patient.metabolicStatus": "Distúrbios Metabólicos",
  "exerciseBuilder.configuration.patient.elevation": "Altitude",

  // ExerciseBuilder --- Ventilator
  "exerciseBuilder.ventilatorSetup.description": "Configure os parâmetros iniciais do ventilador",
  "exerciseBuilder.ventilatorSetup.ventilatoryMode": "Modo ventilatório",

  "exerciseBuilder.oximeterSetup.description": "Selecione as opções de configuração do oxímetro",

  "exerciseBuilder.alarmsSetup.description": "Ajuste os alarmes do exercício",
  "exerciseBuilder.alarmsSetup.parameter": "Parâmetro",
  "exerciseBuilder.alarmsSetup.minimum": "Mínimo",
  "exerciseBuilder.alarmsSetup.maximum": "Máximo",
  "exerciseBuilder.alarmsSetup.ventilator": "Alarmes",

  // --- Alarms
  "alarm.ventilator.volume": "Vol (ml)",
  "alarm.ventilator.pressure": "Pva (cmH₂O)",
  "alarm.ventilator.frequency": "f (rpm)",
  "alarm.oximeter.spo2": "SpO₂ (%)",
  "alarm.oximeter.heartRate": "FC (bpm)",

  //Expected Results
  "expectedResults.header.item": "Item Avaliado",
  "expectedResults.header.criticalItem": "Crítico",
  "expectedResults.header.weight": "Peso",
  "expectedResults.header.score": "Pontuação",
  "expectedResults.header.minimum": "Mínimo Aceitável",
  "expectedResults.header.average": "Gabarito",
  "expectedResults.header.maximum": "Máximo Aceitável",
  "expectedResults.footer.total": "Pontuação Máxima:",

  "exerciseBuilder.results.arterialBloodGas": "Gasometria Arterial",
  "exerciseBuilder.results.ph": "pH",
  "exerciseBuilder.results.PaCO2": "PaCO₂",
  "exerciseBuilder.results.HCO3": "HCO₃₋",
  "exerciseBuilder.results.PaO2": "PaO₂",
  "exerciseBuilder.results.SaO2": "SaO₂",
  "exerciseBuilder.results.PaO2FiO2": "PaO₂/FɪO₂",

  "exerciseBuilder.results.ventilatorParameters": "Ventilador",
  "exerciseBuilder.results.frequency": "f (rpm)",
  "exerciseBuilder.results.inspiratoryTime": "T. Insp. (s)",
  "exerciseBuilder.results.expiratoryTime": "T. Exp. (s)",
  "exerciseBuilder.results.tidalVolume": "VC (ml)",
  "exerciseBuilder.results.minuteVolume": "VE (ml)",
  "exerciseBuilder.results.pressureAt100ms": "P0.1 (cmH₂O)",
  "exerciseBuilder.results.pressureTimeProduct": "PTP (cmH₂O.s.m⁻¹)",
  "exerciseBuilder.results.workOfBreath": "W (Joules/l)",

  "exerciseBuilder.results.ventilationModeVCV": "Modo Ventilatório - VCV",
  "exerciseBuilder.results.ventilationModePCV": "Modo Ventilatório - PCV",
  "exerciseBuilder.results.ventilationModePSV": "Modo Ventilatório - PSV",
  "exerciseBuilder.results.ventilationModeCPAP": "Modo Ventilatório - CPAP",

  "exerciseBuilder.results.peep": "PEEP (cmH₂O)",
  "exerciseBuilder.results.FiO2perc": "FɪO₂ (%)",

  "exerciseBuilder.results.volume": "Volume (ml)",
  "exerciseBuilder.results.flow": "Fluxo (l/min)",
  "exerciseBuilder.results.fio2": "FɪO₂ (%)",
  "exerciseBuilder.results.inspiratoryPause": "Pausa Insp.(s)",
  "exerciseBuilder.results.expiratoryPause": "Pausa Exp.(s)",
  "exerciseBuilder.results.pressure": "∆p (cmH₂O)",
  "exerciseBuilder.results.riseTime": "T. Subida (s)",
  "exerciseBuilder.results.supportPressure": "PS (cmH₂O)",
  "exerciseBuilder.results.endOfCycle": "Fim do ciclo (%)",

  "exerciseBuilder.simulationConfig.startMessage.title": "Configure a simulação",
  "exerciseBuilder.simulationConfig.startMessage": "Reproduza no simulador o problema descrito no cenário clínico. Ao término, pressione o botão Salvar.",
  "exerciseBuilder.simulationConfig.start": "Iniciar a configuração do problema",
  "exerciseBuilder.simulationConfig.saveMessage": "",
  "exerciseBuilder.simulationConfig.saveMessage.title": "Configuração salva com sucesso!",

  "exerciseBuilder.simulationSolution.startMessage.title": "Solucione o problema na simulação",
  "exerciseBuilder.simulationSolution.startMessage": "Resolva o problema de acordo com o cenário clínico e características do caso apresentado. Ao término acione a opção 'Salvar'.",
  "exerciseBuilder.simulationSolution.start": "Iniciar solução do problema",
  "exerciseBuilder.simulationSolution.saveMessage": "",
  "exerciseBuilder.simulationSolution.saveMessage.title": "Solução salva com sucesso!",
  "exerciseBuilder.simulationSolution.saveMessage.confirm": "Distribuir pontuação",

  // Simulation
  "simulator.startSimulation": "Iniciar a simulação",

  "simulation.gasometry.title": "Gasometria",
  "simulation.gasometry.view": "Visualizar",
  "simulation.gasometry.menu.colored": "Com alerta de cores",
  "simulation.gasometry.menu.notColored": "Sem alerta de cores",

  "simulation.monitor.header": "Monitores",
  "simulation.monitor.title": "Parâmetros",

  "simulator.caseDescription.questions": "Questões",
  "simulator.caseDescription.clinicalScenario": "Cenário Clínico",
  "simulator.caseDescription.tasks": "Tarefas",
  "simulator.caseDescription.extras": "Extras",

  //Simulation Results
  "simulation.results.user": "Usuário",
  "simulation.results.anonymousUser": "Usuário Anônimo",
  "simulation.results.date": "Data",
  "simulation.results.duration": "Duração",
  "simulation.results.simulation": "Simulação",
  "simulation.results.clinicalScenario": "Cenário",
  "simulation.results.tasks": "Tarefas",
  "simulation.results.extras": "Extras",

  "simulation.results.gasometry": "Gasometria arterial",
  "simulation.results.gasometry.ph": "pH",
  "simulation.results.gasometry.paco2": "PaCO₂",
  "simulation.results.gasometry.hco3": "HCO₃-",
  "simulation.results.gasometry.pao2": "PaO₂",
  "simulation.results.gasometry.sao2": "SaO₂",
  "simulation.results.gasometry.pao2fio2": "PaO₂/FIO₂",

  "simulation.results.monitor": "Monitorização ventilatória",
  "simulation.results.monitor.frequency": "f (rpm)",
  "simulation.results.monitor.inspirationTime": "T. Insp. (s)",
  "simulation.results.monitor.expirationTime": "T. Exp. (s)",
  "simulation.results.monitor.tidalVolume": "VC (ml)",
  "simulation.results.monitor.ve": "VE (L/min)",
  "simulation.results.monitor.pressureAt100ms": "P0.1 (cmH₂O)",
  "simulation.results.monitor.pressureTimeProduct": "PTP (cmH₂O.s.m⁻¹)",
  "simulation.results.monitor.workDone": "W (Joules/l)",

  "simulation.results.ventilator": "Ajustes do ventilador",
  "simulation.results.ventilator.ventilationMode": "Modo Ventilatório",
  "simulation.results.ventilator.peep": "PEEP (cmH₂O)",
  "simulation.results.ventilator.inspiratoryTime": "T. Insp. (s)",
  "simulation.results.ventilator.pressure": "∆p (cmH₂O)",
  "simulation.results.ventilator.fio2": "FɪO₂ (%)",
  "simulation.results.ventilator.flow": "Fluxo (l/min)",
  "simulation.results.ventilator.supportPressure": "PS (cmH₂O)",
  "simulation.results.ventilator.endOfCycle": "Fim do ciclo (%)",
  "simulation.results.ventilator.riseTime": "T. Subida (s)",
  "simulation.results.ventilator.volume": "Volume (ml)",
  "simulation.results.ventilator.frequency": "f (rpm)",
  "simulation.results.ventilator.inspiratoryPause": "Pausa Insp.(s)",
  "simulation.results.ventilator.expiratoryPause": "Pausa Exp. (s)",

  "simulation.results.pdfSummary": "Deseja salvar seu resultado (formato pdf)?",
  "simulation.results.extraQuestions": "Questões de múltipla escolha",
  "simulation.results.summary": "Resultado Final",
  "simulation.results.feedback.congratulations": "Parabéns!",
  "simulation.results.feedback.almostThere": "Parabéns, quase perfeito.",
  "simulation.results.feedback.couldImprove": "Você pode melhorar",
  "simulation.results.feedback.frown": "Oops!",

  "simulation.sectionResult.parameter": "Parâmetro",
  "simulation.sectionResult.expected": "Esperado",
  "simulation.sectionResult.done": "Realizado",
  "simulation.sectionResult.weight": "Peso",
  "simulation.sectionResult.score": "Score",
  "simulation.sectionResult.correctItem": "Item Correto",
  "simulation.sectionResult.incorrectItem": "Item Incorreto",
  "simulation.sectionResult.correctAnswers": "Acerto",
  "simulation.sectionResult.correctAnswer": "Resposta Correta",
  "simulation.sectionResult.yourAnswer": "Sua Resposta",
  "simulation.sectionResult.noAlternativeSelected": "Nenhuma alternativa selecionada",

  "simulation.results.finalScore": "Nota Final",
  "simulation.results.timeSpent": "Tempo Gasto",
  "simulation.results.retry": "Tentar Novamente",
  "simulation.results.backhome": "Retornar",
  "simulation.results.clickHere": "Clique Aqui",
  "simulation.results.collectSimulatorFeedback": "Dê sua opinião!"
};

export default ptBR;