export const en = {
  "welcomedialog.title": "Welcome to Xlung Trainer",
  "welcomedialog.body": "Learn by doing simulated VM exercises",
  "welcomedialog.alreadySubscriber": "I'm a subscriber",
  "welcomedialog.letMeTry": "Let me try",

  "duration.in.minutes": "minutes",
  "breadcrumbs.exercises": "Exercises",
  "breadcrumbs.exercises.results": "Results",
  "breadcrumbs.assessments": "Assessments",
  "breadcrumbs.assessments.results": "Results",
  "breadcrumbs.assessments.question.results": "Results",

  "breadcrumbs.assessments.question": "Question",

  "loading": "Loading",
  "date.time.format": "MM/dd/yyyy HH:mm",
  "success": "Success",

  "assessment.resolution.confirm": "Confirm",
  "assessment.resolution.cancel": "Cancel",
  "assessment.resolution.confirmSubmission": "Confirm Submission",

  "assessment.resolution.multipleChoiceQuestion": "**At least one multiple choice question was not answered**. Do you want to finish anyway?",
  "assessment.resolution.ventilatorSettingsNotChanged": "**Fan settings have not been changed**. Do you want to finish anyway?",

  "assessment.resolution.error.closed.title": "Outside the evaluation period",
  "assessment.resolution.error.closed.body": "You attempted to submit a response to the assessment, but the deadline has passed. If you need help, contact the administrator.",

  "assessment.resolution.retry.error.overlimit.title": "Você ultrapassou o limite de tentativas de resolução da prova.",
  "assessment.resolution.retry.error.overlimit.body": "Caso necessite de maiores informações entre em contato com o administrador.",
  "assessment.remainingTries": "Remaning Tries",

  "assessment.actions.addStudent": "Adicionar Aluno",
  "assessment.actions.addQuestion": "Adicionar Questão",
  "assessment.actions.delete": "Remove a questão da avaliação",
  "assessment.actions.move": "Altera a ordem da questão na avaliação",
  "assessment.action.start": "Start Assessment",

  "assessment.result.header.title": "Result",

  "assessment.result.assessmentSubmited.title": "Assessment submitted successfully!",
  "assessment.result.assessmentSubmited.body": "**Congratulations!** Your assessment has been sent successfully.",

  "assessment.result.question.grade": "Nota",
  "assessment.result.question.solution": "Resposta",
  "assessment.result.question.title": "Questão",
  "assessment.result.question.order": "#",
  "assessment.result.empty": "Esta avaliação ainda não tem resultados até o momento",
  "assessment.result.student": "Student",
  "assessment.result.await.title": "Awaiting release",
  "assessment.result.await.release": "The results of the evaluation will be released soon. Wait for instructions from the responsible for the test.",

  "assessment.result.backhome": "Back to Home",

  "assessment.result.sendReportEmail": "Enviar relatório por email",
  "assessment.exportResolution.messageSuccess": "Relatório enviado com sucesso para o(s) email(s) do(s) autor(es)",

  "assessment.result.finalScore": "Final Score",
  "assessment.result.resolutionSent": "Resolution sent in",

  "assessment.result.blankResolution": "Question without resolution",

  "assessment.student.finalGrade": "Média",
  "assessment.student.email": "Email",
  "assessment.student.name": "Nome",
  "assessment.search.students": "Pesquisar alunos",
  "assessment.student.search.result.empty": "Sua busca não retornou resultados",
  "assessment.student.search.by.name.or.email": "Nome ou Email do aluno",
  "assessment.student.search.results": "Resultados da Busca",
  "assessment.student.search.placeholder": "Nome ou Email do aluno",
  "assessment.student.search.add": "Adicionar",

  "assessment.question.level": "Level",
  "assessment.question.duration": "Maximum Duration (min)",
  "assessment.question.title": "Title",
  "assessment.question.order": "Order",

  "assessment.instructions": "Instructions",
  "assessment.authors": "Author(s)",

  "username": "Username",
  "password": "Password",
  "required": "Required",
  "Auth.form.error.invalid": "Invalid username or password",

  "simulation.save": "Save",
  "simulation.commit": "Confirm changes?",
  "simulation.open": "Open",
  "simulation.pause": "Pause",
  "simulation.resume": "Resume",
  "simulation.undo": "Undo",
  "simulation.redo": "Redu",
  "simulation.configuration": "Configuration",
  "simulation.images": "Exams",
  "simulation.sound": "Sound",
  "simulation.requestGasometry": "Request blood gas analysis",
  "simulation.finishEarlyMessage": "You will be able to finish the exercise<br> after 30 seconds from the beginning",
  "simulation.timeLeft": "Remaining Time",

  "select.placeholder": "Selecione",
  "minutes": "minutes",
  "minute": "minute",
  "second": "second",
  "seconds": "seconds",
  "and": "and",
  "previous": "Anterior",
  "next": "Próximo",
  "finish": "Finish",
  "cancel": "Cancelar",

  "header.clientNameOrSignIn": "Login",
  "header.logout": "Logout",
  "header.becomePremium": "Become Premium",
  "header.exerciseList": "Exercise List",
  "header.assessmentsList": "Assessments",

  "gender.male": "Masc.",
  "gender.female": "Fem.",
  "age": "Idade",
  "age.unit": "Anos",
  "height": "Altura",
  "height.unit": "m",
  "weight": "Peso",
  "weight.unit": "Kg",
  "idealBodyWeight": "Peso Ideal",
  "airwayResistance": "Resistência nas vias aéreas",
  "airwayResistance.description": "É uma propriedade física que mede a oposição ao fluxo de ar nas zonas condutoras da árvore brônquica",
  "airwayResistance.unit": "cmH₂O/l/s",
  "lungCompliance": "Complacência Pulmonar",
  "lungCompliance.unit": "ml/cmH₂O",
  "lungCompliance.description": "<p>É uma propriedade física do tecido pulmonar e corresponde à capacidade pulmonar total em litros a uma dada pressão alveolar.</p><p><code><var>C</var> = <var>dV</var>/<var>dP</var></code></p><p>Pode ser considerada, portanto, o inverso da elastância pulmonar.</p>",
  "shuntEffect": "Efeito Shunt",
  "shuntEffect.description": "Ocorre quando uma porção do sangue venoso adentra a circulação arterial sem haver se submetido à troca gasosa na circulação pulmonar.",
  "shuntEffect.unit": "%",
  "deadSpace": "Espaço Morto",
  "deadSpace.description": "Proporção entre a ventilação do espaço-morto e o volume corrente. Espaço-morto é a região das vias aéreas que é ventilada, mas não realiza troca gasosa.",
  "muscleEffort": "Intensidade do Esforço",
  "muscleEffort.description": "É uma estimativa da pressão gerada na cavidade pleural pela contração do diafragma.",
  "muscleEffort.unit": "cmH₂O",
  "frequency": "Frequência Respiratória",
  "frequency.description": "Em adultos, varia entre 16 e 20 irpm.",
  "frequency.unit": "rpm",
  "frequencyParam": "f",
  "frequencyParam.unit": "s",
  "duration.unit": "s",
  "inspiratoryPause": "Inspiratory Pause",
  "inspiratoryPause.unit": "s",
  "expiratoryPause": "Expiratory Pause",
  "expiratoryPause.unit": "s",
  "inspiratoryTime": "Insp. Time",
  "inspiratoryTime.unit": "s",
  "expiratoryTime": "Exp. Time",
  "peep": "PEEP",
  "peep.unit": "cmH₂O",
  "fio2": "FɪO₂",
  "fio2.unit": "%",
  "pressureDelta": "Δp",
  "pressureDelta.unit": "cmH₂O",
  "riseTime": "Rise Time",
  "riseTime.unit": "s",
  "supportPressure": "PS",
  "supportPressure.unit": "cmH₂O",
  "endOfCycle": "Cycling-off",
  "endOfCycle.unit": "%",
  "sensitivityType": "Sensitivity Type",
  "sensitivity": "Sensitivity",
  "volume": "Volume",
  "volume.unit": "ml",
  "flow": "Flow",
  "flow.unit": "l/min",
  "flowPattern": "Flow Pattern",
  "syncTrigger.flow": "Flow",
  "syncTrigger.pressure": "Pressure",
  "pressure": "Pressure",
  "ventilator": "Ventilator",
  "ventilationMode": "Ventilation Mode",
  "ventilation": "Ventilation",
  "oximetry": "Oximetry",
  "gasometry": "BGA",
  "collectedAt": "Collected at",
  "gasometryContinuous": "Continuous",
  "ventilometry": "Ventilometry",
  "tidalVolume": "VT",
  "synchrony": "Synchrony",
  "respiratoryMechanics": "Respiratory Mechanics",
  "respiratoryWorkWeaning": "Respiratory Work/Weaning",
  "peakPressure": "Peak P.",
  "drivingPressure": "Driving P.",
  "workOfBreath": "Work",
  "tobinIndex": "f/VC",
  "visualization": "Visualization",

  // Graphics Screen
  "simulation.title.graphics": "Ventilator curves",
  "simulation.title.autoScale": "Auto Scale",

  //categories
  "category.0": "Ajustes Básicos e modos da VM",
  "category.1": "Ajustes da VM na SARA",
  "category.2": "Ajustes da VM nas D. Obstrutivas: DPOC e ASMA",
  "category.3": "Ajustes da VM nas D. Neurológicas",
  "category.4": "Ajustes da VM em assincronias",
  "category.5": "Outros",

  //Clinical Conditions
  "clinicalConditions.title": "Clinical Conditions",
  "clinicalConditions.NORMAL_INTUBATED_WITHOUT_EFFORT": "Normal intubado sem esforço",
  "clinicalConditions.NORMAL_INTUBATED_WITH_EFFORT": "Normal intubado com esforço",
  "clinicalConditions.NORMAL_NON_INTUBATED": "Normal não intubado",
  "clinicalConditions.COPD": "DPOC",
  "clinicalConditions.ASTHMA": "Asma",
  "clinicalConditions.LIGHT_ARDS": "SARA Leve",
  "clinicalConditions.MODERATE_ARDS": "SARA Moderada",
  "clinicalConditions.SERIOUS_ARDS": "SARA Grave",
  "clinicalConditions.CUSTOM": "Personalizado",

  //Metabolic Status
  "metabolicDisorders.title": "Distúrbios Metabólicos",
  "metabolicDisorders.NEUTRAL": "Sem distúrbios metabólicos",
  "metabolicDisorders.LIGHT_ACIDOSIS": "Acidose leve",
  "metabolicDisorders.MODERATE_ACIDOSIS": "Acidose moderada",
  "metabolicDisorders.SEVERE_ACIDOSIS": "Acidose grave",
  "metabolicDisorders.LIGHT_ALKALOSIS": "Alcalose leve",
  "metabolicDisorders.MODERATE_ALKALOSIS": "Alcalose moderada",
  "metabolicDisorders.SEVERE_ALKALOSIS": "Alcalose grave",

  //Elevations
  "elevation.title": "Altitude",
  "elevation.sea.level": "Sea Level",
  "elevation.deadsea.level": "Dead Sea Level",
  "elevation.saopaulo.level": "São Paulo Level",
  "elevation.denver.level": "Denver Level",
  "elevation.airplane.level": "Airplane Level",
  "elevation.lapaz.level": "La Paz Level",

  //Ventilatory Modes
  "ventilatoryModes.VCV": "A/C VCV",
  "ventilatoryModes.PCV": "A/C PCV",
  "ventilatoryModes.PSV": "PSV",
  "ventilatoryModes.CPAP": "CPAP",

  //Exercise List
  "exerciseList.freeContent": "Free Content",
  "exerciseList.exercises": "Exercises:",
  "exerciseList.premiumContent": "Premium Content",
  "exerciseList.question.level": "Level",
  "exerciseList.question.duration": "Maximum Duration (min)",
  "exerciseList.question.title": "Title",
  "exerciseList.question.order": "Order",

  "exercise.list.header": "Simulation exercises",
  "exercise.level.easy": "Easy",
  "exercise.level.medium": "Medium",
  "exercise.level.hard": "Hard",

  //Assessments List
  "assessmentsList.noAssessmentsAvailable": "You currently have no reviews available.",
  "assessmentsList.institutionalTitle": "My Assessments",
  "assessmentsList.assessmentTitle": "Assessment Title",
  "assessmentsList.accessBegin": "Begin",
  "assessmentsList.accessEnd": "End",
  "assessmentsList.remainingTries": "Remaning Tries",
  "assessmentsList.resolutions": "Resolutions",

  // Assessment Details
  "assessment.details.basicInfo": "Basic information",
  "assessment.details.questions": "Questions",
  "assessment.details.result": "Result",
  "assessment.details.registeredStudents": "Registered Students",
  "assessment.details.results": "Results",

  // ExerciseBuilder
  "exerciseBuilder.learningObjectives": "Objetivos de Aprendizado",
  "exerciseBuilder.header": "Construtor de Exercícios",
  "exerciseBuilder.objectives": "Objetivos de Aprendizado",
  "exerciseBuilder.challengeLevel": "Nível de Dificuldade",
  "exerciseBuilder.clinicalScenario": "Cenário Clínico",
  "exerciseBuilder.tasksAndDuration": "Missão do Aluno",
  "exerciseBuilder.mediaUpload": "Exames médicos disponíveis",
  "exerciseBuilder.simulationConfig": "Configuração da Simulação",
  "exerciseBuilder.simulationSolution": "Solução da Simulação",
  "exerciseBuilder.extraQuestionBuilder": "Questão Avulsa",
  "exerciseBuilder.expectedResults": "Pontuação",
  "exerciseBuilder.evolutions": "Evoluções do Paciente",
  "exerciseBuilder.finalization": "Finalização do Exercício",
  "exerciseBuilder.finalized": "Exercício Finalizado",
  "exerciseBuilder.solutionSummarized": "Results",

  // ExerciseBuilder - Clinical Scenario
  "exerciseBuilder.clinicalScenario.placeholders.exerciseName": "Digite aqui o nome do exercício de simulação",
  "exerciseBuilder.clinicalScenario.placeholders.objectives": "Digite aqui os objetivos de aprendizagem",
  "exerciseBuilder.clinicalScenario.placeholders.description": "",
  "exerciseBuilder.clinicalScenario.placeholders.tasks": "",

  "exerciseBuilder.scenario.description": "Descreva o cenário clínico do exercício",

  // ExerciseBuilder - ExtraQuestion
  "exerciseBuilder.extraQuestionStem.description": "Enunciado",
  "exerciseBuilder.extraQuestionStem.alternativesLabel": "Alternativas",
  "exerciseBuilder.extraQuestionStem.placeholders.stem": "Digite aqui o enunciado da questão",
  "exerciseBuilder.extraQuestionStem.placeholders.alternative": "Digite aqui o texto da opção",

  // ExerciseBuilder - ExerciseName
  "exerciseBuilder.exerciseName.description": "Nome do exercício de simulação",

  // ExerciseBuilder - ExerciseCategories
  "exerciseBuilder.categories.description": "Selecione as categorias que melhor descrevem este exercício.",
  "exerciseBuilder.objectives.description": "Descreva os objetivos do exercício",
  "exerciseBuilder.tasks.description": "Descreva pontualmente as tarefas do exercício",

  // ExerciseBuilder - ExerciseLevel
  "exerciseBuilder.level.description": "Selecione o nível de dificuldade do exercício.",

  // ExerciseBuilder - ExerciseMedia
  "exerciseBuilder.media.empty": "Nenhum arquivo de mídia anexado ao exercício",
  "exerciseBuilder.media.message": "Arraste para cá ou clique para selecionar os arquivos dos exames.",
  "exerciseBuilder.media.uploadButton": "Enviar Imagem",
  "exerciseBuilder.media.description": "Selecione os arquivos de mídia (imagens, vídeos, etc) que estarão disponíveis para visualização durante o exercício.",

  // ExerciseBuilder - Simulation Duration
  "exerciseBuilder.duration.description": "Quanto tempo o aluno terá disponível para a execução do exercício?",

  // ExerciseBuilder - Simulation Configuration
  "exerciseBuilder.configuration.preview": "Visualizar no simulador",

  // ExerciseBuilder --- Patient
  "exerciseBuilder.patientSetup.description": "Configure o paciente de acordo com o caso clínico:",
  "exerciseBuilder.configuration.patient.demographicData": "Dados Demográficos",
  "exerciseBuilder.configuration.patient.gender": "Sexo",
  "exerciseBuilder.configuration.patient.pulmonaryPhysiology": "Fisiologia Pulmonar",
  "exerciseBuilder.configuration.patient.airwayResistance": "Rva",
  "exerciseBuilder.configuration.patient.lungCompliance": "Cst",
  "exerciseBuilder.configuration.patient.deadSpace": "VD/VT",
  "exerciseBuilder.configuration.patient.shuntEffect": "Shunt",
  "exerciseBuilder.configuration.patient.effortData": "Esforço Muscular",
  "exerciseBuilder.configuration.patient.muscleEffort": "Pmus",
  "exerciseBuilder.configuration.patient.frequency": "f",
  "exerciseBuilder.configuration.patient.duration": "Duração",
  "exerciseBuilder.configuration.patient.clinicalCondition": "Condição Clínica",
  "exerciseBuilder.configuration.patient.metabolicStatus": "Distúrbios Metabólicos",
  "exerciseBuilder.configuration.patient.elevation": "Altitude",

  // ExerciseBuilder --- Ventilator
  "exerciseBuilder.ventilatorSetup.description": "Configure os parâmetros iniciais do ventilador",
  "exerciseBuilder.ventilatorSetup.ventilatoryMode": "Modo ventilatório",

  "exerciseBuilder.oximeterSetup.description": "Selecione as opções de configuração do oxímetro",

  "exerciseBuilder.alarmsSetup.description": "Ajuste os alarmes do exercício",
  "exerciseBuilder.alarmsSetup.parameter": "Parâmetro",
  "exerciseBuilder.alarmsSetup.minimum": "Mínimo",
  "exerciseBuilder.alarmsSetup.maximum": "Máximo",
  "exerciseBuilder.alarmsSetup.ventilator": "Alarmes",

  // --- Alarms
  "alarm.ventilator.volume": "Vol (ml)",
  "alarm.ventilator.pressure": "Pva (cmH₂O)",
  "alarm.ventilator.frequency": "f (rpm)",
  "alarm.oximeter.spo2": "SpO₂ (%)",
  "alarm.oximeter.heartRate": "FC (bpm)",

  //Expected Results
  "expectedResults.header.item": "Item Avaliado",
  "expectedResults.header.criticalItem": "Crítico",
  "expectedResults.header.weight": "Peso",
  "expectedResults.header.score": "Pontuação",
  "expectedResults.header.minimum": "Mínimo Aceitável",
  "expectedResults.header.average": "Gabarito",
  "expectedResults.header.maximum": "Máximo Aceitável",
  "expectedResults.footer.total": "Pontuação Máxima:",

  "exerciseBuilder.results.arterialBloodGas": "Gasometria Arterial",
  "exerciseBuilder.results.ph": "pH",
  "exerciseBuilder.results.PaCO2": "PaCO₂",
  "exerciseBuilder.results.HCO3": "HCO₃₋",
  "exerciseBuilder.results.PaO2": "PaO₂",
  "exerciseBuilder.results.SaO2": "SaO₂",
  "exerciseBuilder.results.PaO2FiO2": "PaO₂/FɪO₂",

  "exerciseBuilder.results.ventilatorParameters": "Ventilador",
  "exerciseBuilder.results.frequency": "f (rpm)",
  "exerciseBuilder.results.inspiratoryTime": "T. Insp. (s)",
  "exerciseBuilder.results.expiratoryTime": "T. Exp. (s)",
  "exerciseBuilder.results.tidalVolume": "VC (ml)",
  "exerciseBuilder.results.minuteVolume": "VE (ml)",
  "exerciseBuilder.results.pressureAt100ms": "P0.1 (cmH₂O)",
  "exerciseBuilder.results.pressureTimeProduct": "PTP (cmH₂O.s.m⁻¹)",
  "exerciseBuilder.results.workOfBreath": "W (Joules/l)",

  "exerciseBuilder.results.ventilationModeVCV": "Modo Ventilatório - VCV",
  "exerciseBuilder.results.ventilationModePCV": "Modo Ventilatório - PCV",
  "exerciseBuilder.results.ventilationModePSV": "Modo Ventilatório - PSV",
  "exerciseBuilder.results.ventilationModeCPAP": "Modo Ventilatório - CPAP",

  "exerciseBuilder.results.peep": "PEEP (cmH₂O)",
  "exerciseBuilder.results.FiO2perc": "FɪO₂ (%)",

  "exerciseBuilder.results.volume": "Volume (ml)",
  "exerciseBuilder.results.flow": "Fluxo (l/min)",
  "exerciseBuilder.results.fio2": "FɪO₂ (%)",
  "exerciseBuilder.results.inspiratoryPause": "Pausa Insp.(s)",
  "exerciseBuilder.results.expiratoryPause": "Pausa Exp.(s)",
  "exerciseBuilder.results.pressure": "∆p (cmH₂O)",
  "exerciseBuilder.results.riseTime": "T. Subida (s)",
  "exerciseBuilder.results.supportPressure": "PS (cmH₂O)",
  "exerciseBuilder.results.endOfCycle": "Cycling-off (%)",

  "exerciseBuilder.simulationConfig.startMessage.title": "Configure a simulação",
  "exerciseBuilder.simulationConfig.startMessage": "Reproduza no simulador o problema descrito no cenário clínico. Ao término, pressione o botão Salvar.",
  "exerciseBuilder.simulationConfig.start": "Iniciar a configuração do problema",
  "exerciseBuilder.simulationConfig.saveMessage": "",
  "exerciseBuilder.simulationConfig.saveMessage.title": "Configuração salva com sucesso!",

  "exerciseBuilder.simulationSolution.startMessage.title": "Solucione o problema na simulação",
  "exerciseBuilder.simulationSolution.startMessage": "Resolva o problema de acordo com o cenário clínico e características do caso apresentado. Ao término acione a opção 'Salvar'.",
  "exerciseBuilder.simulationSolution.start": "Iniciar solução do problema",
  "exerciseBuilder.simulationSolution.saveMessage": "",
  "exerciseBuilder.simulationSolution.saveMessage.title": "Solução salva com sucesso!",
  "exerciseBuilder.simulationSolution.saveMessage.confirm": "Distribuir pontuação",

  // Simulation
  "simulator.startSimulation": "Iniciar a simulação",

  "simulation.gasometry.title": "Blood gas analysis",
  "simulation.gasometry.view": "View",
  "simulation.gasometry.menu.colored": "Colored alert",
  "simulation.gasometry.menu.notColored": "Not colored alert",

  "simulation.monitor.header": "Monitors",
  "simulation.monitor.title": "Parameters",

  "simulator.caseDescription.questions": "Questions",
  "simulator.caseDescription.clinicalScenario": "Clinical scenario",
  "simulator.caseDescription.tasks": "Tasks",
  "simulator.caseDescription.extras": "Extras",

  //Simulation Results
  "simulation.results.user": "User",
  "simulation.results.anonymousUser": "Anonymous User",
  "simulation.results.date": "Date",
  "simulation.results.duration": "Duration",
  "simulation.results.simulation": "Simulation",
  "simulation.results.clinicalScenario": "Clinical scenario",
  "simulation.results.tasks": "Tasks",
  "simulation.results.extras": "Extras",

  "simulation.results.gasometry": "Blood gas analysis",
  "simulation.results.gasometry.ph": "pH",
  "simulation.results.gasometry.paco2": "PaCO₂",
  "simulation.results.gasometry.hco3": "HCO₃-",
  "simulation.results.gasometry.pao2": "PaO₂",
  "simulation.results.gasometry.sao2": "SaO₂",
  "simulation.results.gasometry.pao2fio2": "PaO₂/FIO₂",

  "simulation.results.monitor": "Ventilatory monitoring",
  "simulation.results.monitor.frequency": "f (rpm)",
  "simulation.results.monitor.inspirationTime": "Insp. T. (s)",
  "simulation.results.monitor.expirationTime": "Exp. T. (s)",
  "simulation.results.monitor.tidalVolume": "VT (ml)",
  "simulation.results.monitor.ve": "VE (L/min)",
  "simulation.results.monitor.pressureAt100ms": "P0.1 (cmH₂O)",
  "simulation.results.monitor.pressureTimeProduct": "PTP (cmH₂O.s.m⁻¹)",
  "simulation.results.monitor.workDone": "Work (Joules/l)",

  "simulation.results.ventilator": "Ventilator settings",
  "simulation.results.ventilator.ventilationMode": "Ventilation Mode",
  "simulation.results.ventilator.peep": "PEEP (cmH₂O)",
  "simulation.results.ventilator.inspiratoryTime": "Insp. T. (s)",
  "simulation.results.ventilator.pressure": "∆p (cmH₂O)",
  "simulation.results.ventilator.fio2": "FɪO₂ (%)",
  "simulation.results.ventilator.flow": "Flow (l/min)",
  "simulation.results.ventilator.supportPressure": "Support Pressure (cmH₂O)",
  "simulation.results.ventilator.endOfCycle": "Cycling-off (%)",
  "simulation.results.ventilator.riseTime": "Rise Time (s)",
  "simulation.results.ventilator.volume": "Volume (ml)",
  "simulation.results.ventilator.frequency": "f (rpm)",
  "simulation.results.ventilator.inspiratoryPause": "Insp. Pause (s)",
  "simulation.results.ventilator.expiratoryPause": "Exp. Pause (s)",

  "simulation.results.pdfSummary": "Save your results (file format pdf)?",
  "simulation.results.extraQuestions": "Multiple choice questions",
  "simulation.results.summary": "Final score",
  "simulation.results.feedback.congratulations": "Great job!",
  "simulation.results.feedback.almostThere": "Almost there!",
  "simulation.results.feedback.couldImprove": "Good, but you can improve it!",
  "simulation.results.feedback.frown": "Oops!",

  "simulation.sectionResult.parameter": "Parameter",
  "simulation.sectionResult.expected": "Expected",
  "simulation.sectionResult.done": "Done",
  "simulation.sectionResult.weight": "Weight",
  "simulation.sectionResult.score": "Score",
  "simulation.sectionResult.correctItem": "Correct",
  "simulation.sectionResult.incorrectItem": "Incorrect",
  "simulation.sectionResult.correctAnswers": "Correct Answers",
  "simulation.sectionResult.correctAnswer": "Correct Answer",
  "simulation.sectionResult.yourAnswer": "Your answer",
  "simulation.sectionResult.noAlternativeSelected": "No Alternative Selected",

  "simulation.results.finalScore": "Total score",
  "simulation.results.timeSpent": "Time spent",
  "simulation.results.retry": "Try again",
  "simulation.results.backhome": "Back to Home",
  "simulation.results.clickHere": "Click Here",
  "simulation.results.collectSimulatorFeedback": "Give us your feedback!"
};

export default en;